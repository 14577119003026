<div class="filter-container">
  <div class="filter-header">
    <h2>Filter<mat-icon>expand_more</mat-icon></h2>
  </div>

  <div *ngIf="currentRoute == 'trips'; else alertsFilter" class="filter-body">
    <div class="studentFilter">
      <mat-form-field appearance="standard">
        <mat-label>Student</mat-label>
        <input type="text" placeholder="Search (3 letter min)" matInput [formControl]="studentControl" [matAutocomplete]="autoStudent" />
        <mat-icon matSuffix>search</mat-icon>
        <mat-autocomplete #autoStudent="matAutocomplete">
          <mat-option *ngFor="let option of filteredStudents$ | async | slice : 0 : 100" [value]="option | titlecase"> {{ option | titlecase }} </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>

    <div class="driverFilter">
      <mat-form-field appearance="standard">
        <mat-label>Driver</mat-label>
        <input type="text" placeholder="Search (3 letter min)" matInput [formControl]="driverControl" [matAutocomplete]="autoDriver" />
        <mat-icon matSuffix>search</mat-icon>
        <mat-autocomplete #autoDriver="matAutocomplete">
          <mat-option *ngFor="let option of filteredDrivers$ | async | slice : 0 : 100" [value]="option | titlecase"> {{ option | titlecase }} </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>

    <div class="tripStatusFilter">
      <mat-label>Trip Status</mat-label>
      <mat-checkbox [(ngModel)]="notStartedTrips">Not Started</mat-checkbox>
      <mat-checkbox [(ngModel)]="inProgressTrips">In Progress</mat-checkbox>
      <mat-checkbox [(ngModel)]="completedTrips">Completed</mat-checkbox>
    </div>

    <div class="dispatchFilter" data-cy='dispatchFilter'>
      <mat-label>Dispatch Type:</mat-label>
      <mat-button-toggle-group #dispatchFilter="matButtonToggleGroup">
        <mat-button-toggle value="">All</mat-button-toggle>
        <mat-button-toggle value="AM">AM</mat-button-toggle>
        <mat-button-toggle value="PM">PM</mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div class="locationFilter">
      <mat-form-field>
        <mat-label>Bus Location</mat-label>
        <mat-select #locationFilter>
          <mat-option value="">All</mat-option>
          <mat-option *ngFor="let location of locations" [value]="location">{{ location.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="lateTripsFilter">
      <mat-slide-toggle #lateTripsFilter>Only Show Late Trips</mat-slide-toggle>
    </div>

    <div class="alertTripsFilter">
      <mat-slide-toggle #alertTripsFilter>Only Show Trips with Alerts</mat-slide-toggle>
    </div>

    <div class="unassignedBusTripsFilter">
      <mat-slide-toggle #unassignedBusTripsFilter>Trips With Unassigned Buses</mat-slide-toggle>
    </div>

    <div class="filterSubmissionButtons">
      <button mat-stroked-button color="accent" (click)="applyTripsFilter()" data-cy='applyFilter'>Apply</button>
      <button mat-stroked-button color="warn" (click)="clearTripsFilter()" data-cy='clearFilter'>Clear</button>
    </div>
  </div>
</div>

<ng-template #alertsFilter>
  <div>
    <mat-form-field>
      <mat-label>Alert Types</mat-label>
      <mat-select #exceptionsFilter data-cy='filterSelect'>
        <mat-option value="">All</mat-option>
        <mat-option *ngFor="let exception of exceptionTypes" [value]="exception">{{ exception.description }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="filterSubmissionButtons">
    <button mat-stroked-button color="accent" (click)="applyAlertsFilter()">Apply</button>
    <button mat-stroked-button color="warn" (click)="clearAlertsFilter()">Clear</button>
  </div>
</ng-template>
