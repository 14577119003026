<div class="segment-exceptions-container">

  <h2 id="trip-alerts-label">Trip Alerts</h2>

  <table *ngIf="dataSource?.data.length" mat-table [dataSource]="dataSource" matSort class="mat-elevation-z4">

    <!-- Time Column -->
    <ng-container matColumnDef="time_stamp">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>DATE & TIME</th>
      <td mat-cell *matCellDef="let exception">{{ exception.time_stamp | date: 'mediumTime' }}</td>
    </ng-container>

    <!-- Alert Column -->
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>ALERT TYPE</th>
      <td mat-cell *matCellDef="let exception">{{ exception.description }}</td>
    </ng-container>

    <!-- Stop Name Column -->
    <ng-container matColumnDef="stop_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>STOP NAME</th>
      <td mat-cell *matCellDef="let exception">{{ exception.stop_name }}</td>
    </ng-container>

    <!-- Student Name Column -->
    <ng-container matColumnDef="student_ln">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>STUDENT NAME</th>
      <td mat-cell *matCellDef="let exception">{{ exception.student_fn }} {{ exception.student_ln }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      [ngClass]="{ highlight: row.severity == 'critical' }">
    </tr>
  </table>

  <mat-card *ngIf="segmentExceptions.length === 0" class="mat-elevation-z4">
    <span class="no-alerts"><mat-icon>done</mat-icon>No Alerts</span>
  </mat-card>
</div>
