<form #databaseForm="ngForm">
    <mat-dialog-content>
        <h5>Add new database</h5>
        <mat-form-field appearance="fill" class="full-width">
            <mat-label>Database Name</mat-label>
            <input matInput placeholder="Enter database name" [(ngModel)]="database.name" name="name" required>
        </mat-form-field>
        <mat-form-field appearance="fill" class="full-width">
            <mat-label>Database ID</mat-label>
            <input matInput placeholder="Enter database ID" [(ngModel)]="database.id" name="id" required>
        </mat-form-field>
        <div class="toggle-container">
            <mat-label>Set as Primary Database</mat-label>
            <mat-slide-toggle [(ngModel)]="database.primary" name="primary"></mat-slide-toggle>
        </div>
    </mat-dialog-content>
    
    <mat-dialog-actions align="end">
        <button mat-stroked-button color="warn" mat-dialog-close>Cancel</button>
        <button mat-stroked-button color="accent" [disabled]="!databaseForm.valid" (click)="addDatabase()">Accept</button>
    </mat-dialog-actions>
</form>
