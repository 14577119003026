import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, tap, switchMap } from 'rxjs/operators';
import { AccountService } from '@app/services/account.service';
import { Trip, Segment, Exception, PostResponse } from '@app/models/interfaces';
import { environment as env } from '@environments/environment';
import { ApiService } from '@app/services/api.service';

@Injectable({
  providedIn: 'root',
})
export class TripsService {
  constructor(private accountService: AccountService, private apiService: ApiService) {}

  /**
   * displayedTrips$ value is currently only set in filter.service.applyTripFiltering, after
   * any clientside filtering is applied. It's this BehaviorSubject that is subscribed to in the trips
   * template, and is responsible for the app template view.
   *
   * serverFilteredTrips$ gets subscribed to in filter.service.applyTripFiltering. If there are no
   * clientside filters requested by the user, then the value of serverFilteredTrips$ gets assigned to the
   * next value of displayedTrips$.
   *
   */
  public displayedTrips$ = new BehaviorSubject([]);
  public serverFilteredTrips$ = new BehaviorSubject([]);
  // This lateness threshold is used by the Time Dif pipe in calculating individual trip stops displayed lateness
  public accountLatenessThreshold$ = new BehaviorSubject(0);

  private _fetchTrips$(accountId: string, params?: object): Observable<Trip[]> {
    // We will always want to provide 'include_ontime_summary' in the request params...
    params = params ? params : {};
    params['include_ontime_summary'] = 'true';
    const accountSummaryUrl = `${env.rtbApiUrl}/v1/account/${accountId}/summary`;
    return this.apiService.get(accountSummaryUrl, params).pipe(
      map((resp) => resp['data']),
      tap((respData) => {
        this.accountLatenessThreshold$.next(respData['summary']['min_late_allowance']);
      }),
      map((respData) => respData['segments'])
    );
  }

  public getTrips(forceRefresh?: boolean, params?: object): void {
    if (this.displayedTrips$.value.length === 0 || forceRefresh) {
      this.accountService
        .getAccountId$()
        .pipe(switchMap((accountId) => this._fetchTrips$(accountId, params)))
        .subscribe((trips) => {
          this.serverFilteredTrips$.next(trips);
        });
    }
  }

  public getSegmentManifest$(segmentId): Observable<Segment> {
    const segmentManifestUrl = `${env.rtbApiUrl}/v2/segment/${segmentId}/manifest`;
    return this.apiService.get(segmentManifestUrl).pipe(map((resp) => resp['data']['segments'][0]));
  }

  private _fetchSegmentExceptions$(accountId, segmentId): Observable<Exception[]> {
    const segmentExceptionsUrl = `${env.rtbApiUrl}/v1/account/${accountId}/exceptions?segments=${segmentId}`;
    return this.apiService.get(segmentExceptionsUrl).pipe(map((resp) => resp['data']['exceptions']));
  }

  public getSegmentExceptions$(segmentId): Observable<Exception[]> {
    return this.accountService
      .getAccountId$()
      .pipe(switchMap((accountId) => this._fetchSegmentExceptions$(accountId, segmentId)));
  }

  private _postBusToSegment$(accountId, segmentId, busGtcId): Observable<PostResponse> {
    const assignBustoSegmentUrl = `${env.rtbApiUrl}/v1/account/${accountId}/segment/${segmentId}/bus`;
    const payload = { bus_id: busGtcId };
    return this.apiService.post(assignBustoSegmentUrl, payload);
  }

  assignBusToSegment(segmentId, busGtcId): Observable<PostResponse> {
    return this.accountService
      .getAccountId$()
      .pipe(switchMap((accountId) => this._postBusToSegment$(accountId, segmentId, busGtcId)));
  }

  assignBustoAllSegments(toBusId, fromBusId): Observable<PostResponse> {
    const assignBustoAllSegmentsUrl = `${env.rtbApiUrl}/v1/buses/${toBusId}/segments`;
    const payload = { from_bus_id: fromBusId };
    return this.apiService.post(assignBustoAllSegmentsUrl, payload);
  }

  public eventsCsv$(): Observable<string> {
    return this.accountService.accountId$.pipe(
      switchMap((accId) => {
        const url = `${env.rtbApiUrl}/v1/account/${accId}/events/csv`;
        return this.apiService.getCsv(url);
      })
    );
  }
}
