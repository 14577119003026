<mat-toolbar color="primary" class="subheader-container">
  <mat-toolbar-row id="first-subheader-row" class="subheader-row">
    <nav class="subheader-nav">
      <a class="trips-link" [class.selected]="page === 'trips'" routerLink="/trips">TRIPS</a>
      <a class="alerts-link" [class.selected]="page === 'alerts'" routerLink="/alerts">ALERTS</a>
      <a class="alerts-link" [class.selected]="page === 'admin'" routerLink="/admin" *ngIf="accountService.isUserAdmin$ | async">ADMIN</a>
    </nav>
  </mat-toolbar-row>


  <mat-toolbar-row  id="second-subheader-row" class="subheader-row">
    <div class="subheader-date">{{ pageLabel }}</div>
    <div class="subheader-icons">
      <button (click)="emitDarkModeEvent()"><mat-icon [class.selected]="darkMode">nights_stay</mat-icon></button>
      <button (click)="exportCSV()" data-cy="export-link" *ngIf="page != 'admin'"><mat-icon>cloud_download</mat-icon></button>
      <button (click)="print()" data-cy="print-link"><mat-icon>print</mat-icon></button>
      <a href="https://support.zonarsystems.net/" target="_blank" data-cy="support-link"><mat-icon>help</mat-icon></a>
      <button (click)="emitFilterModeEvent()" data-cy="filter-link" *ngIf="page != 'admin'"><mat-icon [class.selected]="filterMode">filter_list</mat-icon></button>
    </div>
  </mat-toolbar-row>

</mat-toolbar>
