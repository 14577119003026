import { Injectable } from '@angular/core';
import { environment as env } from '@environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, tap, switchMap } from 'rxjs/operators';
import { ApiService } from '@app/services/api.service';
import { AccountService } from '@app/services/account.service';
import { Driver } from '@app/models/interfaces';

@Injectable({
  providedIn: 'root',
})
export class DriversService {
  constructor(private accountService: AccountService, private apiService: ApiService) { }

  public driverObjects$ = new BehaviorSubject([]);
  public driverNames$ = new BehaviorSubject(['']);

  private _getAccountDrivers$(accountId: string): Observable<Driver[]> {
    const driversUrl = `${env.rtbApiUrl}/v1/account/${accountId}/drivers`;
    return this.apiService.get(driversUrl).pipe(map((e) => e['data']['drivers']));
  }

  public getDrivers() {
    this.accountService.getAccountId$().pipe(
      switchMap((accountId) => this._getAccountDrivers$(accountId)),
      tap((drivers) => {
        this.driverObjects$.next(drivers);
        this.driverNames$.next(drivers.map(driver => driver['name']));
      }),
    ).subscribe();
  }

}
