import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Pattern Library Modules
import { ZonarUICoreModule } from '@zonar-ui/core';
import { ZonarUIMaterialModule } from '@zonar-ui/material';
import { ZonarUiAnalyticsService, ZonarUIAnalyticsTokens } from '@zonar-ui/analytics';
import { ZonarUiAuthModule } from '@zonar-ui/auth'
import { ZonarUiPermissionsModule } from '@zonar-ui/auth'

import { ZonarOwnerIdInterceptor } from '@zonar-ui/auth';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular'
import { HTTP_INTERCEPTORS } from '@angular/common/http';

// Environment Variables
import { environment } from '@environments/environment';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ZonarUICoreModule,
    AuthModule.forRoot({
      clientId: environment.clientID,
      domain: environment.domain,
      audience: environment.audience,
      redirect_uri: window.location.origin,
      scope: 'openid profile email',
      useRefreshTokens: true,
      maxAge: 36_000,
      cacheLocation: 'localstorage',
      httpInterceptor: {
        allowedList: [
          'https://api.routeboard.dev.zonarsystems.net/api/*',
          'https://api.routeboard.staging.zonarsystems.net/api/*',
          'https://api.routeboard.production.zonarsystems.net/api/*',
          'https://rtb2.dev.zonarsystems.net',
          'https://rtb2.staging.zonarsystems.net',
          'https://rtb2.production.zonarsystems.net',
        ]
      }
    }),
  ],
  exports: [
    ZonarUICoreModule
  ],
  providers: [
    // Auth settings
    { provide: 'audience', useValue: environment.audience },
    { provide: 'appUrl', useValue: environment.appUrl },
    { provide: 'logoutReturnToUrl', useValue: window.location.origin },
    { provide: 'environment', useValue: environment.authEnv },
    { provide: 'clientID', useValue: environment.clientID },
    { provide: 'domain', useValue: environment.domain },
    { provide: 'redirectUrl', useValue: environment.redirectUrl },

    { provide: 'applicationId', useValue: environment.common.applicationId },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    { provide: 'defaultPermPrefix', useValue: environment.common.defaultPermPrefix },
    { provide: 'routePermissions', useValue: environment.common.routePermissions },

    { provide: ZonarUIAnalyticsTokens.ID, useValue: environment.common.googleTagManager.id },
    ZonarUiAnalyticsService

  ],

})
export class CoreModule { }
