import { Injectable } from '@angular/core';
import { AccountService } from '@app/services/account.service';
import { ApiService } from '@app/services/api.service';
import { environment as env } from '@environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';
import { tap, map, switchMap } from 'rxjs/operators';
import { Exception, ExceptionType } from '@app/models/interfaces';

@Injectable({
  providedIn: 'root',
})
export class AlertsService {
  constructor(private accountService: AccountService, private apiService: ApiService) { }

  public accountExceptions$ = new BehaviorSubject([]);


  public exceptionsCsv$(): Observable<string> {
    return this.accountService.accountId$.pipe(
      switchMap(accId => {
        const url = `${env.rtbApiUrl}/v1/account/${accId}/exceptions/csv`;
        return this.apiService.getCsv(url);
      }));
  }

  private _getAccountExceptions$(accountId: string, params?: object): Observable<Exception[]> {
    const exceptionsUrl = `${env.rtbApiUrl}/v1/account/${accountId}/exceptions`;
    return this.apiService.get(exceptionsUrl, params).pipe(
      map((e) => e['data']['exceptions']),
      tap((e) => this.accountExceptions$.next(e))
    );
  }

  public getExceptions(refresh?, params?: object) {
    if (this.accountExceptions$.value.length === 0 || refresh) {
      this.accountService.getAccountId$().pipe(
        switchMap((e) => this._getAccountExceptions$(e, params))
      )
        .subscribe((e) => this.accountExceptions$.next(e));
    }
  }

  public getExceptionTypes$(): Observable<ExceptionType[]> {
    const exceptionTypesUrl = `${env.rtbApiUrl}/v1/exceptions`;
    return this.apiService.get(exceptionTypesUrl).pipe(map((e) => e['data']['exception_types']));
  }
}
