import { Component, OnChanges, Input } from '@angular/core';
import { TripsService } from '@app/services/trips.service';
import { Trip, Segment, Stop } from '@app/models/interfaces';

@Component({
  selector: 'app-segment-manifest',
  templateUrl: './segment-manifest.component.html',
  styleUrls: ['./segment-manifest.component.scss'],
})
export class SegmentManifestComponent implements OnChanges {
  @Input() trip: Trip;

  segmentManifest: Segment;

  constructor(private tripsService: TripsService) { }

  ngOnChanges() {
    this.tripsService.getSegmentManifest$(this.trip.id).subscribe((manifest) => {
      manifest.stops.sort(this.compareTimestamps);
      this.segmentManifest = manifest;
    });
  }

  // comparator function for use in the manifest stops array sort method
  // if the stop has occured, use that timestamp as comparison, otherwise, use the planned stop time
  compareTimestamps(a: Stop, b: Stop) {
    const tsA = a.event_timestamp ? a.event_timestamp : a.stop_time;
    const tsB = b.event_timestamp ? b.event_timestamp : b.stop_time;
    if (tsA < tsB) {
      return -1;
    }
    if (tsA > tsB) {
      return 1;
    }
    return 0;
  }

}
