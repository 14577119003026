<div *ngIf="trip">
    <h2 id="trip-manifest-label">Student Roster & Stops</h2>

  <mat-card *ngIf="segmentManifest" class="stops-container mat-elevation-z4">
    <div *ngFor="let stop of segmentManifest.stops" class="stop-container">
      <div class="stop-header">
        <p><span class="stop-header-labels"><mat-icon>{{stop.is_school ? 'school' : 'place'}}</mat-icon></span><span class="stop-name">{{ stop.name }}</span></p>

        <p *ngIf="stop.event_timestamp; else planned">
          <span class="stop-header-labels">ACTUAL ARRIVAL:</span>
          {{ stop.event_timestamp  | date: 'mediumTime'}}
          ({{ stop | timeDif }})
        </p>

        <ng-template #planned>
          <p>
          <span class="stop-header-labels">PLANNED ARRIVAL:</span>
          {{ stop.stop_time | date: 'mediumTime' }}
          </p>
        </ng-template>

      </div>
      <div class="stop-students-container">
        <p *ngFor="let student of stop.students" class="student">
          <mat-icon>person_outline</mat-icon> {{ student.first_name }} {{ student.last_name }}
        </p>
      </div>
      <div class="stop-border-container"><div></div></div>
    </div>
  </mat-card>
</div>
