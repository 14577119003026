<div class="adminContainer"  *ngIf="accountService.fullAccount$ | async; let account">
    
    <div class="admin-grid-container">
        <div>
            <h2 id="trip-manifest-label">Account Settings</h2>
            <mat-card class="mat-elevation-z4">
                <ng-container>
                    <p>
                        <span class="admin-labels">Account Code: </span> {{account.route_board.code}}<br>
                        <span class="admin-labels">Timezone: </span> {{account.route_board.timezone}}<br>
                        <span class="admin-labels">Arrival Allowance: </span> {{account.route_board.allowable_minutes_late}} min<br>
                        <span class="admin-labels">Grade Alert: </span> {{account.route_board.max_alert_grade}}<br>
                        <span class="admin-labels">Show Empty Stops: </span> {{account.route_board.show_empty_stops}}<br>
                        <span class="admin-labels">Show Student Photos: </span> {{account.master_plan.config.display_photo}}<br>
                        <span class="admin-labels">Alert Data Retention: </span> {{account.master_plan.config.retain_days}} days<br>
                    </p>
                </ng-container>
            </mat-card>
        </div>
        
        <div>
            <h2 id="trip-manifest-label">ETL Status</h2>
            <mat-card class="mat-elevation-z4">
                <ng-container>
                    <p>
                        <span class="admin-labels">ETL Enabled: </span> {{account.master_plan.config.run_etl}}<br>
                        <span class="admin-labels">ETL State: </span> {{account.route_board.etl_running ? 'Running' : 'Idle'}}<br>
                        <span class="admin-labels">ETL Offset: </span> {{account.master_plan.config.offset_days}} days<br>
                        <span class="admin-labels">ETL Alert Emails: </span> {{account.master_plan.config.email_enabled ? 'Enabled' : 'Disabled'}}<br>
                        <span class="admin-labels">ETL Alert Email List: </span> {{account.master_plan.config.email_list | json}}<br>
                        <span class="admin-labels">Routing Data Intake Timestamp: </span> {{account.route_board.last_successful_pis_runtime | date: 'short'}}<br>
                        <span class="admin-labels">Routing Data Processed Timestamp: </span> {{account.route_board.last_successful_drs_runtime  | date: 'short'}}<br>
                    </p>
                </ng-container>
            </mat-card>
        </div>
    </div>

    <div class="config-container">
        <h2 id="trip-manifest-label">Routing Provider Config</h2>
        <button class="edit-icon" mat-icon-button (click)="openEditCongig()">
            <mat-icon>edit</mat-icon>
        </button>
    </div>
        <mat-card class="mat-elevation-z4">
            <ng-container>
                <p>
                    <span class="admin-labels">Routing Provider: </span> {{account.route_board.routing_provider}}<br>
                    <span class="admin-labels">URL: </span> {{account.master_plan.config.provider_config.base_url}}<br>
                    <span class="admin-labels">Username: </span> {{account.master_plan.config.provider_config.username}}<br>
                    <span class="admin-labels">Password: </span> {{account.master_plan.config.provider_config.password}}<br>
                    <span class="admin-labels">Client ID: </span> {{account.master_plan.config.provider_config.client_id}}<br>
                    <span *ngFor="let db of account.master_plan.config.provider_config.db_config">
                        <span class="admin-labels">Database: </span> {{db.id}} - {{db.name}} - {{db.primary ? "Primary" : "Secondary"}}{{db.etl_filters != null ? " - Filtered" : ""}}<br>
                    </span>
                </p>
            </ng-container>
        </mat-card>
</div>

