import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { map, startWith, filter, tap, debounceTime } from 'rxjs/operators';
import { StudentsService } from '@app/services/students.service';
import { LocationsService } from '@app/services/locations.service';
import { FilterService } from '@app/services/filter.service';
import { MatButtonToggleGroup } from '@angular/material/button-toggle';
import { MatSelect } from '@angular/material/select';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { Observable } from 'rxjs';
import { Router, RouterEvent } from '@angular/router';
import { AlertsService } from '@app/services/alerts.service';
import { ExceptionType, Location } from '@app/models/interfaces';
import { DriversService } from '@app/services/drivers.service';


@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit {

  // Allow filter template to close itself, such as on filter submission
  @Output() filterModeEvent = new EventEmitter();

  // Gain access to view's filter elements
  @ViewChild('locationFilter') locationFilter: MatSelect;
  @ViewChild('dispatchFilter') dispatchFilter: MatButtonToggleGroup;
  @ViewChild('alertTripsFilter') alertTripsFilter: MatSlideToggle;
  @ViewChild('exceptionsFilter') exceptionsFilter: MatSelect;
  @ViewChild('unassignedBusTripsFilter') unassignedBusTripsFilter: MatSlideToggle;
  @ViewChild('lateTripsFilter') lateTripsFilter: MatSlideToggle;


  // Used to determine which filter view to display: 'trips' or 'alerts'
  currentRoute: string;

  // Driver Filter Input Properties
  driverControl: UntypedFormControl;
  drivers: any[];
  driverOptions: string[];
  filteredDrivers$: Observable<string[]>;
  selectedDriver: any;

  // Student Filter Input Properies
  studentControl: UntypedFormControl;
  students: any[];
  studentOptions: string[];
  filteredStudents$: Observable<string[]>;
  selectedStudent: any;

  // Location Filter Property
  locations: Location[];

  // Status Filter Properties
  completedTrips = false;
  inProgressTrips = false;
  notStartedTrips = false;

  // Alerts / Exception Type Filter Property
  exceptionTypes: ExceptionType[];

  constructor(
    private filterService: FilterService,
    private studentService: StudentsService,
    private locationService: LocationsService,
    private driversService: DriversService,
    private alertsService: AlertsService,
    private router: Router,

  ) {
    // Set subscription to router events for proper filter view display
    router.events.pipe(filter((e: RouterEvent) => e instanceof RouterEvent)).subscribe((e) => {
      this.setCurrentRoute(e);
    });
  }

  ngOnInit(): void {

    // Set route so the correct filter view is displayed
    this.setCurrentRoute(this.router);

    // Initialize Student Filtering Input
    this.studentControl = new UntypedFormControl();
    /**
     * filteredStudents$ is subscribed to in the template via an async pipe.
     * To limit the number of options that are rendered to the DOM, we have a slice pipe in the template view,
     * We also use debounce operator to remove unnecessary iterations,
     * as well as additional filtering that is done in the map operation.
     */
    this.filteredStudents$ = this.studentControl.valueChanges.pipe(
      debounceTime(200),
      startWith(''),
      map((value) => this._filter(value, 'student'))
    );

    // Same as students, but for drivers
    this.driverControl = new UntypedFormControl();
    this.filteredDrivers$ = this.driverControl.valueChanges.pipe(
      debounceTime(200),
      startWith(''),
      map((value) => this._filter(value, 'driver'))
    );

    // Populate Selectable Locations
    this.locationService.getLocations$().subscribe(resp => this.locations = resp);

    // Populate Selectable Exception/Alert Types
    this.alertsService.getExceptionTypes$().subscribe(resp => this.exceptionTypes = resp);


    // Begin listening for any filter removal events
    this.filterService.filterRemovalTracker$.subscribe(resp => this.deselectionMapping(resp));
  }

  // currentRoute is responsible for setting the filter view in an *ngIf directive
  private setCurrentRoute(router: Router | RouterEvent): void {
    this.currentRoute = router.url.slice(1);
  }

  /**
   * Validating the user input ensures that only valid options may be used as
   * student or driver filter options.
   */
  private validateSelection(driverOrStudent: string): void {
    if (driverOrStudent === 'student') {
      this.selectedStudent = this.studentService.studentObjects$.value.find(
        student => student['name'].toLowerCase() === this.studentControl.value.toLowerCase()
      );
    }
    if (driverOrStudent === 'driver') {
      this.selectedDriver = this.driversService.driverObjects$.value.find(
        driver => driver['name'].toLowerCase() === this.driverControl.value.toLowerCase()
      );
    }
  }

  /**
   * This function receives an input value and returns all drivers or students that include that
   * string value.
   * Only begins filtering once a character is input.
   */
  private _filter(userInput: string, studentOrDriver: string): string[] {
    if (userInput.length > 2) {
      const filterValue = userInput.toLowerCase();
      return studentOrDriver === 'student'
        ? this.studentService.studentNames$.value.filter((name) => name.toLowerCase().includes(filterValue))
        : this.driversService.driverNames$.value.filter((name) => name.toLowerCase().includes(filterValue));
    }
  }

  // Emits an event that will open or close the filter component drawer.
  emitFilterModeEvent(): void {
    this.filterModeEvent.emit(true);
  }

  // Trips Page Filters

  applyTripsFilter(): void {

    // if either student or driver has been selected, we must validate the user
    // selection, before passing the selected object into our param/filter mechanisms
    if (this.studentControl.value) {
      this.validateSelection('student');
    }
    if (this.driverControl.value) {
      this.validateSelection('driver');
    }

    // params are those that can specifically be used in the query of a trips request
    // filters are used to display breadcrumbs in the ui and for clientside filtering
    const params = {};
    const filters = {};

    // Server-Side filterable:
    if (this.dispatchFilter.value) {
      params['dispatch'] = this.dispatchFilter.value;
      filters['dispatch'] = this.dispatchFilter.value;
    }
    if (this.selectedStudent?.id) {
      params['student_id'] = this.selectedStudent.id;
      filters['student_id'] = this.selectedStudent.name;
    }
    if (this.alertTripsFilter.checked) {
      params['alert'] = this.alertTripsFilter.checked;
      filters['alert'] = 'Alerts Only';
    }
    if (this.locationFilter.value?.id) {
      params['location_id'] = this.locationFilter.value?.id;
      filters['location_id'] = this.locationFilter.value.name;
    }

    // Client-side filtering required:
    if (this.unassignedBusTripsFilter.checked) {
      filters['unassigned_bus_only'] = 'Unassigned Buses';
    }

    if (this.lateTripsFilter.checked) {
      filters['late_trips_only'] = 'Late Trips';
    }

    if (this.completedTrips) {
      filters['completed_trips'] = 'Completed';
    }

    if (this.inProgressTrips) {
      filters['in_progress_trips'] = 'In Progress';
    }

    if (this.notStartedTrips) {
      filters['not_started_trips'] = 'Not Started';
    }

    if (this.selectedDriver?.name) {
      filters['driver'] = this.selectedDriver.name;
    }
    this.filterService.filterTrips(params, filters);
    this.emitFilterModeEvent();
  }

  /**
   * This serves as a mapping between filter names and their respective deselection action from
   * the filters template view.
   * Whenever a user closes a filter breadcrumb/chip, that filter name gets passed into this method, where the
   * correct deselection occurs.
   * @param filter is the 'name' of the filter breadcrumb/chip that has been removed by the user from the trip template view.
   *
   */
  deselectionMapping(filterName: string) {
    if (filterName === 'dispatch') {
      this.dispatchFilter.value = undefined;
    }
    if (filterName === 'student_id') {
      this.studentControl.setValue('');
      this.selectedStudent = undefined;
    }
    if (filterName === 'alert') {
      this.alertTripsFilter.checked = false;
    }
    if (filterName === 'location_id') {
      this.locationFilter.value = undefined;
    }
    if (filterName === 'unassigned_bus_only') {
      this.unassignedBusTripsFilter.checked = false;
    }
    if (filterName === 'late_trips_only') {
      this.lateTripsFilter.checked = false;
    }
    if (filterName === 'completed_trips') {
      this.completedTrips = false;
    }
    if (filterName === 'in_progress_trips') {
      this.inProgressTrips = false;
    }
    if (filterName === 'not_started_trips') {
      this.notStartedTrips = false;
    }
    if (filterName === 'driver') {
      this.driverControl.setValue('');
      this.selectedDriver = undefined;
    }
    if (filterName === 'exception_type_id') {
      this.exceptionsFilter.value = undefined;
    }
  }

  clearTripsFilter(): void {
    this.locationFilter.value = undefined;
    this.dispatchFilter.value = undefined;
    this.studentControl.setValue('');
    this.selectedStudent = undefined;
    this.driverControl.setValue('');
    this.selectedDriver = undefined;
    this.alertTripsFilter.checked = false;
    this.unassignedBusTripsFilter.checked = false;
    this.lateTripsFilter.checked = false;
    this.completedTrips = false;
    this.inProgressTrips = false;
    this.notStartedTrips = false;
    this.filterService.filterTrips();
  }

  // Alerts Page Filters

  applyAlertsFilter(): void {
    const params = {};
    const filters = {};
    if (this.exceptionsFilter.value) {
      params['exception_type_id'] = this.exceptionsFilter.value.id;
      filters['exception_type_id'] = this.exceptionsFilter.value.description;
    }
    this.filterService.filterAlerts(params, filters);
    this.emitFilterModeEvent();
  }

  clearAlertsFilter(): void {
    this.exceptionsFilter.value = undefined;
    this.filterService.filterAlerts();
  }



}
