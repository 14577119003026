<div class="edit-trip-header-container">
  <p>
    <span class="edit-trip"><button mat-icon-button
              (click)="closeEditPane()">
        <mat-icon>expand_more</mat-icon>
      </button>Edit Trip</span>
  </p>
  <button mat-icon-button
          (click)="closeEditPane()">
    <mat-icon>close</mat-icon>
  </button>
</div>

<div class="edit-trip-input-container">
  <div><span class="edit-trip-labels">CURRENTLY ASSIGNED BUS: </span>{{ trip.bus?.bus_name ? trip.bus.bus_name : 'None
    Assigned' }}</div>
  <div>
    <span class="edit-trip-labels">ASSIGN NEW BUS:</span>
    <mat-form-field appearance="standard">
      <input type="text"
             placeholder="Select"
             matInput
             [formControl]="newBusControl"
             [matAutocomplete]="auto" />
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let option of filteredOptions$ | async | slice : 0 : 100"
                    [value]="option">
          {{ option }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
</div>

<div *ngIf="reassignmentVisible"
     class="edit-trip-confirm-assignment-container">
  <div></div>
  <div>
    <mat-radio-group>
      <mat-radio-button value="1"
                        (change)="radioChange($event)">Just this route</mat-radio-button>
      <mat-radio-button *ngIf="trip.bus"
                        value="2"
                        (change)="radioChange($event)">All routes assigned to <span class="edit-trip-label">{{
          trip.bus?.bus_name }}</span></mat-radio-button>
    </mat-radio-group>
  </div>
  <div>
    <button mat-stroked-button
            color="warn"
            [disabled]="submitButtonDisabled"
            (click)="submitReassignment(reassignmentType)">Submit</button>
    <span *ngIf="reassignmentSuccess"
          class="success-message">Success!</span>
  </div>
</div>