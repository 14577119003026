import { Injectable } from '@angular/core';
import { environment as env } from '@environments/environment';
import { Observable, BehaviorSubject, EMPTY } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Account } from '@app/models/interfaces';
import { ApiService } from '@app/services/api.service';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  constructor(private apiService: ApiService) { }

  public accountId$: BehaviorSubject<string>;
  public accountCode$ = new BehaviorSubject('');
  public isUserAdmin$ =  new BehaviorSubject(false);
  public fullAccount$ = new BehaviorSubject(null);

  public getAccount$(): Observable<Account> {
    const accountUrl = `${env.rtbApiUrl}/v1/account`;
    return this.apiService.get(accountUrl).pipe(
      map(e => e['data']),
      tap(e => (this.accountCode$.next(e['account_code']))),
    );
  }

  public getFullAccount$(): Observable<Account> {
    const accountUrl = `${env.rtbApiUrl}/v2/account`;
    return this.apiService.get(accountUrl, null, 0).pipe(
      catchError(e => EMPTY),
      map(e => e['data']),
      tap(e => this.isUserAdmin$.next(true)),
      tap(e => this.fullAccount$.next(e)),
    );
  }

  public getAccountId$(): Observable<string> {
    return this.accountId$
      ? this.accountId$
      : this.getAccount$().pipe(
        map((e) => e['account_id']),
        tap((e) => (this.accountId$ = new BehaviorSubject(e)))
      );
  }

  public editRoutingProvider(params: object): Observable<any> {
    const accountUrl = `${env.rtbApiUrl}/v2/account`;
    return this.apiService.patch(accountUrl, params);
  }
}
