import { Component, OnInit } from '@angular/core';
import { TripsService } from '@app/services/trips.service';
import { Trip } from '@app/models/interfaces';
import { Color, Label } from 'ng2-charts';
import { ChartType, ChartOptions, ChartDataSets } from 'chart.js';
import { FilterService } from '@app/services/filter.service';

@Component({
  selector: 'app-kpi',
  templateUrl: './kpi.component.html',
  styleUrls: ['./kpi.component.scss'],
})
export class KpiComponent implements OnInit {
  constructor(private tripService: TripsService, public filterService: FilterService) {}

  // Used to determine which mat-icon to display (+/-)
  panelOpenState = true;

  /**
   * Single object is used throughout the component to set chart data
   * and display interpolated values. Inital values set, so the charts can be built
   * before having their datasets updated with the subscription to `displayedTrips$`
   */
  tripMetrics = {
    count: 0,
    unassigned: 0,
    late: 0,
    notStarted: 0,
    inProgress: 0,
    completed: 0,
  };

  /**
   * Shared Chart Properties
   * https://www.chartjs.org/docs/latest/charts/doughnut.html
   *
   */

  public chartType: ChartType = 'doughnut';

  public chartOptions: ChartOptions = {
    cutoutPercentage: 50,
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
  };

  public chartColors: Color[] = [{ backgroundColor: ['#ce0001', '#65A000', '#e2e4e6'] }];

  /**
   * Indiviual Chart Properies
   * Each chart's data index values should correlate to its label index values.
   */
  public tripsStatusLabels: Label[] = ['Not Started', 'In Progress', 'Completed'];
  public tripsStatusData = [0, 0, 0];
  public tripsStatusColors: Color[] = [{ backgroundColor: ['#ce0001', '#e2e4e6', '#65A000'] }];

  public unassignedBusLabels: Label[] = ['Unassigned Buses', 'Assigned Buses'];
  public unassignedBusData = [0, 0];
  public unassignedBusColors: Color[] = [{ backgroundColor: ['#ce0001', '#65A000'] }];

  public lateTripsLabels: Label[] = ['Late', 'On-Time'];
  public lateTripsData = [0, 0];
  public lateTripsColors: Color[] = [{ backgroundColor: ['#ce0001', '#65A000'] }];

  ngOnInit(): void {
    /**
     * Updates the KPI metric charts to reflect the values of the
     * currently displayed trips. If we wanted the charts to
     * remain reflective of all trips, we could subscribe instead to
     * `tripService.allTrips$`.
     */
    this.tripService.displayedTrips$.subscribe((trips: Trip[]) => {
      this.updateTripMetrics(trips);
      this.updateLateTripsChartData();
      this.updateTripStatusChartData();
      this.updateUnassignedBusData();
    });
  }

  /**
   * This function is used to assign values to the single `tripMetrics` object.
   * It is invoked before any of the methods to update chart data,
   * because `tripMetrics` properties are used to set these other data values, and so must
   * be updated first. This allows us to only have to call the numerous filtering methods
   * on the (potentially) large `trips` dataset once.
   * @param trips is the list of trips returned from the RTB API account summary endpoint.
   *
   */
  private updateTripMetrics(trips: Trip[]): void {
    this.tripMetrics['count'] = trips.length;
    this.tripMetrics['unassigned'] = this._filterUnassignedBuses(trips);
    this.tripMetrics['late'] = this._filterLateTrips(trips);
    this.tripMetrics['notStarted'] = this._filterNotStarted(trips);
    this.tripMetrics['inProgress'] = this._filterInProgress(trips);
    this.tripMetrics['completed'] = this._filterCompleted(trips);
  }

  private updateTripStatusChartData(): void {
    const data = [];
    data.push(this.tripMetrics['notStarted']);
    data.push(this.tripMetrics['inProgress']);
    data.push(this.tripMetrics['completed']);
    this.tripsStatusData = data;
  }

  private updateUnassignedBusData(): void {
    const data = [];
    data.push(this.tripMetrics['unassigned']);
    data.push(this.tripMetrics['count'] - this.tripMetrics['unassigned']);
    this.unassignedBusData = data;
  }

  private updateLateTripsChartData(): void {
    const data = [];
    data.push(this.tripMetrics['late']);
    data.push(this.tripMetrics['count'] - this.tripMetrics['late']);
    this.lateTripsData = data;
  }

  private _filterUnassignedBuses(trips: Trip[]): number {
    return trips.filter((trip) => trip.bus == null).length;
  }

  private _filterLateTrips(trips: Trip[]): number {
    return trips.filter((trip) => trip.ontime === false).length;
  }

  private _filterNotStarted(trips: Trip[]): number {
    return trips.filter((trip) => trip.in_progress === false && trip.completion_timestamp === null).length;
  }

  private _filterInProgress(trips: Trip[]): number {
    return trips.filter((trip) => trip.in_progress === true && trip.completion_timestamp === null).length;
  }

  private _filterCompleted(trips: Trip[]): number {
    return trips.filter((trip) => trip.completion_timestamp !== null).length;
  }

  /**
   * To respect any previously applied filters, we'll pull the values of the active params/filters from
   * filter.service, then add our new kpi filter to the request
   * @param kpiFilter should match one of the already defined filter keys from
   * either filter.component or filter.service.
   * All KPI filters are done clientside, so we won't change any query params, but do want to
   * retain any existing param filters.
   */
  applyFilter(kpiFilter: string) {
    const params = this.filterService.activeTripParams ? this.filterService.activeTripParams : {};
    const filters = this.filterService.activeTripFilters ? this.filterService.activeTripFilters : {};
    if (kpiFilter === 'unassigned_bus_only') {
      filters['unassigned_bus_only'] = 'Unassigned Buses';
    }
    if (kpiFilter === 'not_started_trips') {
      filters['not_started_trips'] = 'Not Started';
    }
    if (kpiFilter === 'late_trips_only') {
      filters['late_trips_only'] = 'Late Trips';
    }
    this.filterService.filterTrips(params, filters);
  }
}
