import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RouterEvent, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { TripsService } from '@app/services/trips.service';
import { AlertsService } from '@app/services/alerts.service';
import { AccountService } from '@app/services/account.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-subheader',
  templateUrl: './subheader.component.html',
  styleUrls: ['./subheader.component.scss'],
})
export class SubheaderComponent {

  constructor(
    public router: Router,
    public tripsService: TripsService,
    public alertsService: AlertsService,
    public accountService: AccountService,
  ) {
    router.events.pipe(
      filter((evt: RouterEvent) => evt instanceof NavigationEnd)
    ).subscribe((evt: NavigationEnd) => {
      this.setLabel(evt);
    });
  }

  @Output() darkModeEvent = new EventEmitter();
  @Output() filterModeEvent = new EventEmitter();
  @Input() filterMode: boolean;

  darkMode: boolean;
  today = new DatePipe('en-US').transform(Date.now());
  page = 'trips';
  pageLabel = 'Trips for ' + this.today;

  print(): void {
    window.print();
  }

  emitDarkModeEvent(): void {
    this.darkModeEvent.emit(true);
    this.darkMode = !this.darkMode;
  }

  exportCSV(): void {
    let cvsObvs$;
    let filename;
    if (this.page === 'trips') {
      cvsObvs$ = this.tripsService.eventsCsv$();
      filename = 'trips.csv';
    } else if (this.page === 'alerts') {
      cvsObvs$ = this.alertsService.exceptionsCsv$();
      filename = 'exceptions.csv';
    }

    // based on https://stackoverflow.com/questions/19327749/javascript-blob-filename-without-link
    cvsObvs$.subscribe(excps => {
      const blob = new Blob([excps], { type: 'text/csv' });
      const a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.download = filename;
      a.click();
      URL.revokeObjectURL(a.href);
    });
  }

  emitFilterModeEvent(): void {
    this.filterModeEvent.emit(true);
  }

  setLabel(evt: NavigationEnd) {
    const urlPath = evt.urlAfterRedirects;
    if (urlPath.includes('alerts')) {
      this.page = 'alerts';
      this.pageLabel = 'Alerts for ' + this.today;
    } else if (urlPath.includes('admin')) {
      this.page = 'admin';
      this.pageLabel = 'Config for ' + this.accountService.accountCode$.getValue();
    } else {
      this.page = 'trips';
      this.pageLabel = 'Trips for ' + this.today;
    }
  }

}
