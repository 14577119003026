import { Pipe, PipeTransform } from '@angular/core';
import { Stop } from '@app/models/interfaces';
import { TripsService } from '@app/services/trips.service';

@Pipe({
  name: 'timeDif'
})
export class TimeDifPipe implements PipeTransform {

  constructor(public tripsService: TripsService) {

  }

  // We want to ignore differences between dates, so we'll convert the hh/mm/ss of each timestamp to milliseconds
  private getMilliseconds(ts: string) {
    const date = new Date(ts);
    return (date.getHours() * 3.6e+6) + (date.getMinutes() * 60000) + (date.getSeconds() * 1000);
  }

  transform(stop: Stop): string {

    const actual = this.getMilliseconds(stop.event_timestamp);
    const planned = this.getMilliseconds(stop.stop_time);

    const label = (actual > planned) ? 'late' : 'early';

    let diff = Math.abs(actual - planned);

    // latenessThreshold is returned from the API in minutes, so we'll convert to milliseconds
    const latenessThreshold = (this.tripsService.accountLatenessThreshold$.value * 60000);
    if (diff <= latenessThreshold) {
      return 'On Time';
    }
    diff -= latenessThreshold;

    const ms = diff % 1000;
    diff = (diff - ms) / 1000;
    const ss = diff % 60;
    diff = (diff - ss) / 60;
    let mm = diff % 60;
    diff = (diff - mm) / 60;
    const hh = diff % 24;

    if (ss > 29) {
      mm++;
    }

    const hour = hh > 1 ? ' hours ' : ' hour ';
    const minute = mm > 1 ? ' minutes ' : ' minute ';

    const resp = ''
      + (hh > 0 ? hh + hour : '')
      + (mm > 0 ? mm + minute : '')
      + label;

    return resp;
  }

}
