import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-database-modal',
  templateUrl: './database-modal.component.html',
  styleUrls: ['./database-modal.component.scss']
})
export class DatabaseModalComponent {

  constructor() { }

}
