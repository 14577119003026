<mat-expansion-panel
  expanded="true"
  class="mat-elevation-z0"
  (opened)="panelOpenState = true"
  (closed)="panelOpenState = false"
  hideToggle="true">

  <mat-expansion-panel-header>
    <div class="kpi-header-container">
      <div></div>
      <div class="kpi-header-title">Summary KPIs</div>
      <div class="kpi-header-icon">
        <mat-icon *ngIf="!panelOpenState">add</mat-icon>
        <mat-icon *ngIf="panelOpenState">remove</mat-icon>
      </div>
    </div>
  </mat-expansion-panel-header>

  <div class="kpi-items-container">
    <mat-card class="kpi-item mat-elevation-z4">
      <div class="kpi-item-header">
        <span class="kpi-item-title">ROUTE STATUS</span>
      </div>
      <div class="kpi-item-body">
        <div class="kpi-item-metric">
          <p class="kpi-item-metric-count"> {{ tripMetrics.notStarted }} </p>
          <p class="kpi-item-metric-label"> Not Started </p>
        </div>
        <div class="kpi-item-chart">
          <canvas
            baseChart
            [data]="tripsStatusData"
            [labels]="tripsStatusLabels"
            [chartType]="chartType"
            [options]="chartOptions"
            [colors]="tripsStatusColors"
            style="width: 100%; height: 100%;">
          </canvas>
        </div>
        <div class="kpi-item-details">
          <div class="kpi-item-details-body">
            <p class="kpi-item-details-text green">{{ tripMetrics.completed }} Completed</p>
            <p class="kpi-item-details-text neutral">{{ tripMetrics.inProgress }} In-Progress</p>
            <p class="kpi-item-details-text red">{{ tripMetrics.notStarted }} Not Started</p>

          </div>
          <div class="kpi-item-details-footer">
            <a (click)="applyFilter('not_started_trips')">View Not Started</a>
          </div>
        </div>
      </div>
    </mat-card>

    <mat-card class="kpi-item mat-elevation-z4">
      <div class="kpi-item-header">
        <span class="kpi-item-title">REAL-TIME PERFORMANCE</span>
      </div>
      <div class="kpi-item-body">
        <div class="kpi-item-metric">
          <p class="kpi-item-metric-count"> {{ tripMetrics.late }}</p>
          <p class="kpi-item-metric-label">Running Late</p>
        </div>
        <div class="kpi-item-chart">
          <canvas
            baseChart
            [data]="lateTripsData"
            [labels]="lateTripsLabels"
            [chartType]="chartType"
            [options]="chartOptions"
            [colors]="lateTripsColors"
            style="width: 100%; height: 100%;">
          </canvas>
        </div>
        <div class="kpi-item-details">
          <div class="kpi-item-details-body">
            <p class="kpi-item-details-text green">{{ tripMetrics.count - tripMetrics.late }} On-Time</p>
            <p class="kpi-item-details-text red">{{ tripMetrics.late }} Running Late</p>

            <p> </p>
          </div>
          <div class="kpi-item-details-footer">
            <a (click)="applyFilter('late_trips_only')">View Late Routes</a>
          </div>
        </div>
      </div>
    </mat-card>

    <mat-card class="kpi-item mat-elevation-z4">
      <div class="kpi-item-header">
        <span class="kpi-item-title">UNASSIGNED ROUTES</span>
      </div>
      <div class="kpi-item-body">
        <div class="kpi-item-metric">
          <p class="kpi-item-metric-count"> {{ tripMetrics.unassigned }} </p>
          <p class="kpi-item-metric-label"> Unassigned </p>
        </div>
        <div class="kpi-item-chart">
          <canvas
            baseChart
            [data]="unassignedBusData"
            [labels]="unassignedBusLabels"
            [chartType]="chartType"
            [options]="chartOptions"
            [colors]="unassignedBusColors"
            style="width: 100%; height: 100%;">
          </canvas>
        </div>
        <div class="kpi-item-details">
          <div class="kpi-item-details-body">
            <p class="kpi-item-details-text green">{{ tripMetrics.count - tripMetrics.unassigned }} Assigned</p>
            <p class="kpi-item-details-text red">{{ tripMetrics.unassigned }} Unassigned</p>

            <p> </p>
          </div>
          <div class="kpi-item-details-footer">
            <a (click)="applyFilter('unassigned_bus_only')">View Unassigned</a>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
</mat-expansion-panel>
