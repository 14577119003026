import { Injectable } from '@angular/core';
import { ApiService } from '@app/services/api.service';
import { environment as env } from '@environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';
import { tap, map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LocationsService {
  public accountLocations$ = new BehaviorSubject([]);

  constructor(private apiService: ApiService) { }

  public getLocations$(): Observable<any[]> {
    const locationsUrl = `${env.rtbApiUrl}/v1/locations`;
    return this.accountLocations$.value.length > 0
    ? this.accountLocations$
    : this.apiService.get(locationsUrl).pipe(map((e) => e['data']['locations']));
  }
}
