
<div class="trips-filter-chips-container" *ngIf="filterService.activeTripFilters">
  <mat-chip-list>
    <mat-chip [removable]=true (removed)="filterService.removeTripFilter(filter)"
      *ngFor="let filter of filterService.activeTripFilters | keyvalue">
      {{filter.value | titlecase }}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
  </mat-chip-list>
</div>

<div class="kpi-container">
  <app-kpi></app-kpi>
</div>

<div class="trip-container">
  <div class="trips-summary-container">
      <div class="trips-table-container mat-elevation-z4">
        <div class="trips-filter-container">
          <mat-form-field appearance="fill" class="trips-filter">
            <mat-label>Search for a Trip or Bus</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium" />
            <mat-icon matPrefix>search</mat-icon>
          </mat-form-field>
        </div>

        <div class="trips-table">
          <table mat-table [dataSource]="tripsDataSource" matSort>
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Trips</th>
              <td mat-cell *matCellDef="let trip">
                <p class="trip-name" data-cy="trips-cell-name">{{ trip.name }}</p>
                <p class="bus-name">Bus: {{ trip.bus?.bus_name ? trip.bus.bus_name : 'None Assigned' }}</p>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: displayedColumns"
              (click)="onSelect(row)"
              [class.selected]="row === selectedTrip">
            </tr>
          </table>
        </div>
        <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
      </div>
  </div>

  <div class="trip-details-container">
    <app-trip-details [trip]="selectedTrip"></app-trip-details>
  </div>
</div>
