import { Component, OnInit, OnChanges, Input, OnDestroy } from '@angular/core';
import { Trip, Driver } from '@app/models/interfaces';
import { DriversService } from '@app/services/drivers.service';
import { TripsService } from '@app/services/trips.service';

@Component({
  selector: 'app-trip-details',
  templateUrl: './trip-details.component.html',
  styleUrls: ['./trip-details.component.scss'],
})
export class TripDetailsComponent implements OnInit, OnChanges {
  @Input() trip: Trip;

  tripStatus = '';
  plannedDriver: string;
  actualDriver: string;
  segmentExceptions = [];
  editMode: boolean;

  constructor(private driverService: DriversService, private tripsService: TripsService) { }

  private setTripStatus() {
    if (!this.trip['completion_timestamp'] && !this.trip.in_progress) {
      this.tripStatus = 'Not Started';
    } else if (this.trip['completion_timestamp']) {
      this.tripStatus = 'Completed';
    } else if (this.trip.in_progress) {
      this.tripStatus = 'In Progress';
    }
  }

  /**
   * Sets the planned and actual driver names from the input Trip for use in the template
   */
  private associateDrivers(): void {
    const plannedDriverIndex = this.driverService.driverObjects$.value.findIndex((e) => e.id === this.trip.planned_driver);
    const actualDriverIndex = this.driverService.driverObjects$.value.findIndex((e) => e.id === this.trip.actual_driver);
    this.plannedDriver = plannedDriverIndex >= 0 ? this.driverService.driverObjects$.value[plannedDriverIndex].name : 'None Specified';
    this.actualDriver = actualDriverIndex >= 0 ? this.driverService.driverObjects$.value[actualDriverIndex].name : 'None Specified';
  }

  checkExceptions() {
    this.tripsService.getSegmentExceptions$(this.trip.id).subscribe((alerts) => {
      this.segmentExceptions = alerts;
    });
  }


  ngOnChanges() {
    // ngOnChanges fires with every change to the @Input property
    // conditional check for a trip, because when the component is first instantiated, ngOnChanges gets
    // invoked first
    if (this.trip) {
      this.setTripStatus();
      this.associateDrivers();
      this.checkExceptions();
    }
    this.editMode = false;
  }

  ngOnInit(): void {
    this.driverService.driverObjects$.subscribe(() => {
      if (this.trip) { this.associateDrivers(); }
    });
  }

  toggleEdit() {
    this.editMode = !this.editMode;
  }

}
