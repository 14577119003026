// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { commonEnvironment } from './environment.common';

export const environment = {
  production: false,
  environment: 'staging',
  common: commonEnvironment,
  authEnv: 'staging',

  appUrl: 'https://rtb2.staging.zonarsystems.net',
  rtbApiUrl: 'https://api.routeboard.staging.zonarsystems.net/api',
  redirectUrl: 'https://rtb2.staging.zonarsystems.net',

  clientID: 'h498ifH4aB9kykv1GtTgkGLuX6zxlQgK',
  domain: 'qa-zonar-rider-verification.auth0.com',
  audience: 'https://rtb-app-qa.sea-001.zonarsystems.net',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
