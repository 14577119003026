import { Component, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AccountService } from '@app/services/account.service';
import { ConfigAdminEditModalComponent } from '../shared/config-admin-edit-modal/config-admin-edit-modal.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchError, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent implements OnDestroy {

  accountData: any

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    public accountService: AccountService,
    /* The `private modal: MatDialog` is a private property of the `AdminComponent` class that is of
    type `MatDialog`. */
    private modal: MatDialog,
    private snackBar: MatSnackBar
  ) {
    this.accountData = this.accountService.fullAccount$
  }



  openEditCongig() {
    const configData = { data: this.accountData.value }

    this.modal.open(ConfigAdminEditModalComponent, configData)
      .afterClosed()
      .pipe(
        takeUntil(this.unsubscribe$)
      )
      .subscribe((result) => {
        if (!result) return

        const configObject: any = {
          master_plan: {
            config: {
              email_list: this.accountData.value.master_plan.config.email_list,
              email_enabled: this.accountData.value.master_plan.config.email_enabled,
              provider_config: {
                base_url: result.providerConfig.base_url,
                password: result.providerConfig.password,
                username: result.providerConfig.username,
                client_id: result.providerConfig.client_id,
                db_config: result.databases,
              },
            }
          }
        };
        this.accountService.editRoutingProvider(configObject)
          .pipe(
            catchError((err): any => {
              this.snackBar.open(`Error editing information, ${err.errors.master_plan.config.provider_config.db_config[0]}`, 'Close', {
                duration: 5000
              });
            }),
            takeUntil(this.unsubscribe$)
          )
          .subscribe((res) => {
            if (res.success) {
              this.snackBar.open('Information has been edited', 'Close', {
                duration: 3000,
              });
              this.accountData.value.master_plan = res.data.master_plan
              this.accountData.value.route_board = res.data.route_board
              return;
            }
            this.snackBar.open('Something went wrong', 'Close', {
              duration: 5000,
            });
          })
      })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
