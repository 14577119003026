import { Component, ViewChild, HostListener, OnInit, AfterViewInit } from '@angular/core';
import { TripsService } from '@app/services/trips.service';
import { FilterService } from '@app/services/filter.service';
import { Trip } from '@app/models/interfaces';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-trips',
  templateUrl: './trips.component.html',
  styleUrls: ['./trips.component.scss'],
})

export class TripsComponent implements OnInit, AfterViewInit {

  selectedTrip: Trip;

  displayedColumns = ['name'];
  tripsDataSource: MatTableDataSource<any>;

  isSticky = false;
  stickyHeightOffset: number;
  stickyWidth$ = new BehaviorSubject('');

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private tripsService: TripsService, public filterService: FilterService) {
    // Assign empty datasource for the later subscription to populate
    this.tripsDataSource = new MatTableDataSource([]);
    // Define how the filter (user input) value is applied on our data
    this.tripsDataSource.filterPredicate = (trip: any, filter) => {
      // We only want to filter based on the bus or trip names
      // We also want the user to be able to quickly filter unassigned buses
      const busStr = trip.bus ? JSON.stringify(trip.bus.bus_name) : 'none assigned';
      const tripStr = JSON.stringify(trip.name);
      const dataStr = (busStr + tripStr).toLowerCase();
      return dataStr.indexOf(filter) !== -1;
    };
  }

  ngOnInit(): void {
    // Populate and subscribe to our account's trips
    this.tripsService.displayedTrips$.subscribe((e) => {
      this.tripsDataSource.data = e;
      // selectedTrip gets passed into the trip-details child component,
      // and should have an initial value, to populate the rest of the page
      this.selectedTrip = this.tripsDataSource.data[0];
    });
  }

  ngAfterViewInit() {
    // sort and paginator need to be applied after the data has been set
    this.tripsDataSource.sort = this.sort;
    this.tripsDataSource.paginator = this.paginator;
  }

  onSelect(trip: Trip): void {
    this.selectedTrip = trip;
  }

  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.tripsDataSource.filter = filterValue.trim().toLowerCase();

    if (this.tripsDataSource.paginator) {
      this.tripsDataSource.paginator.firstPage();
    }
  }

}
