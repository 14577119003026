import { Component, Input, ViewChild, OnChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Exception, Trip } from '@app/models/interfaces';

@Component({
  selector: 'app-segment-exceptions',
  templateUrl: './segment-exceptions.component.html',
  styleUrls: ['./segment-exceptions.component.scss'],
})
export class SegmentExceptionsComponent implements OnChanges {
  @Input() segmentExceptions: Exception[];
  @Input() trip: Trip;
  dataSource;

  displayedColumns: string[] = ['time_stamp', 'description', 'stop_name', 'student_ln'];

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatSort) set content(content: MatSort) {
    this.sort = content;
    if (this.sort) {
      this.dataSource.sort = this.sort;
    }
  }

  constructor() {
    this.dataSource = new MatTableDataSource([]);
  }

  ngOnChanges(): void {
    this.dataSource.data = this.segmentExceptions;
  }
}
