<div *ngIf="trip; else noTrips" class="trip-details-container">
  <div class="trip-details">
    <div class="trip-details-header">
      <h1>
        <span id="trip-details-trip-name"> Trip: {{ trip.name | uppercase }}</span>
        <span id="trip-details-bus-name"> Bus: {{ trip.bus?.bus_name ? trip.bus.bus_name : 'None Specified' }}</span>
      </h1>
      <h2 id="trip-details-label">Trip Details</h2>
      <button *ngIf="tripStatus == 'Not Started'" mat-icon-button (click)="toggleEdit()">
        <mat-icon>create</mat-icon>
      </button>
    </div>

    <mat-card-content class="trip-status-content">
      <mat-card class="mat-elevation-z4">
        <p id="trip-status">
          <span>{{ tripStatus }}</span>
          <span id="trip-on-time-status" [ngClass]="trip.ontime ? 'trip-on-time' : 'trip-late'">{{ trip.ontime ? 'ON-TIME' : 'LATE' }}</span>
          </p>

        <div class="trip-details-items-container">
          <p><span class="trip-details-labels">PLANNED START: </span> {{ trip.start_time | date: 'mediumTime' }}</p>
          <p><span class="trip-details-labels">PLANNED COMPLETION: </span> {{ trip.finish_time | date: 'mediumTime' }}</p>
          <p><span class="trip-details-labels">PLANNED DRIVER: </span> {{ plannedDriver }}</p>
          <p><span class="trip-details-labels">ACTUAL START: </span>
            <ng-template [ngIf]="trip.start_timestamp">
              {{ trip.start_timestamp | date: 'mediumTime'}}
            </ng-template>
            <ng-template [ngIf]="!trip.start_timestamp">
              Not Started
            </ng-template></p>
          <p><span class="trip-details-labels">ACTUAL COMPLETION: </span>
            <ng-template [ngIf]="trip.completion_timestamp">
              {{ trip.completion_timestamp | date: 'mediumTime'}}
            </ng-template>
            <ng-template [ngIf]="!trip.completion_timestamp">
              Not Completed
            </ng-template></p>
          <p><span class="trip-details-labels">ACTUAL DRIVER: </span> {{ actualDriver }}</p>
        </div>

        <div *ngIf="editMode" class="edit-trip-container">
          <app-edit-trip [trip]="trip" (closeEvent)="toggleEdit()"></app-edit-trip>
        </div>
      </mat-card>
    </mat-card-content>
  </div>

  <div class="segment-manifest">
    <app-segment-manifest [trip]="trip"></app-segment-manifest>
  </div>

  <div class="segment-exceptions">
    <app-segment-exceptions [segmentExceptions]="segmentExceptions" [trip]="trip"></app-segment-exceptions>
  </div>

</div>

<ng-template #noTrips>
  <div class="no-trips">
    <mat-card-header class="no-trip-header">
      <mat-card-title>No Trips</mat-card-title>
      <mat-card-subtitle>Please change your filter settings.</mat-card-subtitle>
    </mat-card-header>
  </div>
</ng-template>
