import { Injectable } from '@angular/core';
import { environment as env } from '@environments/environment';
import { AccountService } from '@app/services/account.service';
import { ApiService } from '@app/services/api.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { switchMap, tap, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StudentsService {

  constructor(private accountService: AccountService, private apiService: ApiService) { }

  public studentNames$ = new BehaviorSubject(['']);
  public studentObjects$ = new BehaviorSubject([]);


  private _getAccountStudents$(accountId: string): Observable<any[]> {
    const studentsUrl = `${env.rtbApiUrl}/v1/account/${accountId}/students`;
    return this.apiService.get(studentsUrl).pipe(map((e) => e['data']['students']));
  }

  public getStudents() {
    this.accountService.getAccountId$().pipe(
      switchMap((accountId) => this._getAccountStudents$(accountId)),
      tap((students) => {
        this.studentObjects$.next(students);
        this.studentNames$.next(students.map(student => student['name']));
      }),
    ).subscribe();
  }



}
