<mat-dialog-content>
    <h2>Edit Routing Provider Config</h2>
    <div class="row">
        <div class="col col-70">
            <!-- <mat-form-field appearance="fill">
                <mat-label>Routing provider</mat-label>
                <input matInput [(ngModel)]="route_board.routing_provider" title="one">
            </mat-form-field> -->
            <mat-form-field appearance="fill">
                <mat-label>User name</mat-label>
                <input matInput [(ngModel)]="providerConfig.username" title="two">
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>Base URL</mat-label>
                <input matInput [(ngModel)]="providerConfig.base_url" title="two">
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>Password</mat-label>
                <input matInput [(ngModel)]="providerConfig.password" title="one">
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>Client ID</mat-label>
                <input matInput [(ngModel)]="providerConfig.client_id" title="two">
            </mat-form-field>
        </div>
        <div class="col col-30">
            <div class="table-title">
                <h3>Data Bases</h3>
                <button mat-icon-button aria-label="Add new database" type="button" (click)="addNewDatabase()">
                    <mat-icon>add</mat-icon>
                </button>
            </div>
            <table mat-table [dataSource]="databases" class="mat-elevation-z1 table">
                <!-- Name Column -->
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> Name </th>
                    <td mat-cell *matCellDef="let database">
                        <span class="status-indicator" *ngIf="database.primary; else inactive"
                            matTooltip="Primary Database"></span>
                        <span class="status-indicator etl" *ngIf="database.etl_filters"
                            matTooltip="ETL Filter is applied"></span>
                        {{database.name}} - ID: ({{database.id}})
                    </td>
                </ng-container>

                <ng-template #inactive>
                    <span class="status-indicator inactive" matTooltip="Inactive database"></span>
                </ng-template>

                <!-- Edit Column -->
                <ng-container matColumnDef="edit">
                    <th mat-header-cell *matHeaderCellDef> Edit </th>
                    <td mat-cell *matCellDef="let database">
                        <button class="edit-icon" mat-icon-button aria-label="Edit database" (click)="editDatabase(database)">
                            <mat-icon>edit</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <!-- Delete Column -->
                <ng-container matColumnDef="delete">
                    <th mat-header-cell *matHeaderCellDef> Delete </th>
                    <td mat-cell *matCellDef="let database">
                        <button mat-icon-button aria-label="Cannot delete database with ETL filters"
                            *ngIf="database.etl_filters" [disabled]="database.etl_filters"
                            matTooltip="Cannot delete database with ETL filters">
                            <mat-icon>delete</mat-icon>
                        </button>
                        <button class="delete-icon" mat-icon-button aria-label="Delete database without ETL filters"
                            *ngIf="!database.etl_filters" (click)="deleteDatabse(database)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-stroked-button color="warn" mat-dialog-close>Cancel</button>
    <button mat-stroked-button color="accent" (click)="editInformation()">Accept</button>
</mat-dialog-actions>