import { Component, OnInit } from '@angular/core';
import { WarningNotificationService } from '@app/services/warning-notification.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Component({
  selector: 'app-warning-notification',
  templateUrl: './warning-notification.component.html',
  styleUrls: ['./warning-notification.component.scss'],
})
export class WarningNotificationComponent implements OnInit {
  staleRoutesMessage =
    'Routing information wasn\'t able to be updated today. Any recent changes made to your master schedule will not appear.';

  notificationConfig: MatSnackBarConfig<any> = { duration: 0, panelClass: 'warning-notification' };

  constructor(private warningNotificationService: WarningNotificationService, private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.warningNotificationService.checkEtlUpdated().subscribe(
      (resp) => {
        if (!resp) { this.displayWarningBanner(this.staleRoutesMessage); }
      },
      (err) => this.displayWarningBanner('An error has occurred. Please refresh the page.')
    );
  }

  public displayWarningBanner(message: string): void {
    this.snackBar.open(message, '', this.notificationConfig);
  }
}
