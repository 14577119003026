export interface Account {
  accountCode: string;
  accountId: number;
}

export interface AccountResponse {
  data: {
    account_code: string;
    account_id: number;
  };
  success: boolean;
}

export interface AccountSummaryResponse {
  data: {
    segments: Trip[];
  };
  success: boolean;
}

export interface User {
  email: string;
  email_verified: boolean;
  name: string;
  nickname: string;
  picture: string;
  sub: string;
  updated_at: string;
}

export interface Trip {
  actual_driver: number | null;
  bus: Bus;
  completion_timestamp: string | null;
  dispatch: string | null;
  exception_count: number | null;
  finish_time: string | null;
  id: number | null;
  in_progress: boolean | null;
  last_updated: string | null;
  laststop_id: string | null;
  laststop_real_ts: string | null;
  name: string | null;
  ontime: boolean | null;
  planned_driver: number | null;
  start_time: string | null;
  start_timestamp: string | null;
}

export interface Bus {
  bus_name: string | null;
  gtc_id: number | null;
  id: number | null;
  vin: string | null;
}

export interface Driver {
  id: number | null;
  name: string | null;
}

export interface Segment {
  actual_driver: string | null;
  bus_id: number | null;
  bus_name: string | null;
  completion_timestamp: string | null;
  dispatch: string | null;
  finish_time: string | null;
  gtc_id: number | null;
  id: number | null;
  in_progress: boolean;
  name: string | null;
  planned_driver: string | null;
  start_time: string | null;
  stops: Stop[];
}

export interface Stop {
  event_timestamp: string | null;
  id: number | null;
  is_school: boolean;
  latitude: number | null;
  longitude: number | null;
  name: string | null;
  sequence: number | null;
  stop_time: string | null;
  students: Student[];
}

export interface Student {
  first_name: string | null;
  id: number | null;
  inactive_rfid: [] | string[];
  is_pickup: boolean;
  last_name: string | null;
  rfid: string | null;
}

export interface Exception {
  account_id: number | null;
  bus_id: number | null;
  bus_name: string | null;
  description: string | null;
  driver_fn: string | null;
  driver_id: number | null;
  driver_ln: string | null;
  exception_type_id: number | null;
  gtc_id: number | null;
  id: number | null;
  manual_description: string | null;
  name: string | null;
  segment_id: number | null;
  segment_name: string | null;
  severity: string | null;
  stop_id: number | null;
  stop_name: string | null;
  student_fn: string | null;
  student_id: number | null;
  student_ln: string | null;
  time_stamp: string | null;
}

export interface ExceptionType {
  description: string | null;
  id: number | null;
  name: string | null;
  severity: string | null;
}

export interface PostResponse {
  success: boolean;
}

export interface Location {
  id: number;
  name: string;
}

export interface CompleteConfigObject {
  master_plan: ConfigObject
}
export interface ConfigObject {
  run_etl?: boolean;
  provider?: string;
  timezone?: string;
  email_list: string[];
  offset_days?: number;
  retain_days?: number;
  display_photo?: boolean;
  email_enabled: boolean;
  provider_config: ProviderConfig;
  offset_days_timezone?: string;
}

export interface ProviderConfig {
  retry?: Retry;
  base_url: string;
  password: string;
  username: string;
  client_id: string;
  db_config: DBConfig[];
  vendor_id?: string;
  vendor_key?: string;
}

export interface DBConfig {
  id: string;
  name: string;
  primary: boolean;
  etl_filters: ETLFilters;
}

export interface ETLFilters {
  route: Route[];
}

export interface Route {
  field: string;
  flags: string[];
  regex: string;
}

export interface Retry {
  total: number;
  backoff_factor: number;
  status_forcelist: number[];
}