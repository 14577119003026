import { Injectable } from '@angular/core';
import { environment as env } from '@environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, tap, switchMap } from 'rxjs/operators';
import { ApiService } from '@app/services/api.service';
import { AccountService } from '@app/services/account.service';
import { Bus } from '@app/models/interfaces';

@Injectable({
  providedIn: 'root',
})
export class BusService {
  constructor(private accountService: AccountService, private apiService: ApiService) { }

  public buses$: BehaviorSubject<Bus[]>;

  private _getAccountBuses$(accountId: string): Observable<Bus[]> {
    const busesUrl = `${env.rtbApiUrl}/v1/account/${accountId}/buses`;
    return this.apiService.get(busesUrl).pipe(map((e) => e['data']));
  }

  public getBuses$(): Observable<Bus[]> {
    return this.buses$
      ? this.buses$
      : this.accountService.getAccountId$().pipe(
        switchMap((e) => this._getAccountBuses$(e)),
        map((e) => e['buses']),
        tap((e) => {
          this.buses$ = new BehaviorSubject(e);
        })
      );
  }
}
