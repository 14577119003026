<div class="alertsContainer">


  <div class="alerts-filter-chips" *ngIf="filterService.activeAlertFilters">
    <mat-chip-list>
      <mat-chip [removable]=true (removed)="filterService.removeAlertFilter(filter)"
        *ngFor="let filter of filterService.activeAlertFilters| keyvalue">
        {{filter.value}}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
    </mat-chip-list>
  </div>


  <mat-form-field appearance="standard">
    <mat-label>Search</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium" />
  </mat-form-field>

  <div class="alerts-table-container mat-elevation-z8" data-cy='alerts-table-container'>
  <table mat-table [dataSource]="alertsDataSource" matSort >

    <!-- Bus Column -->
    <ng-container matColumnDef="bus_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Bus</th>
      <td mat-cell *matCellDef="let exception">{{ exception.bus_name }}</td>
    </ng-container>

    <!-- Trip Name Column -->
    <ng-container matColumnDef="segment_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Trip Name</th>
      <td mat-cell *matCellDef="let exception">{{ exception.segment_name }}</td>
    </ng-container>

    <!-- Time Column -->
    <ng-container matColumnDef="time_stamp">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Time</th>
      <td mat-cell *matCellDef="let exception">{{ exception.time_stamp | date: "mediumTime" }}</td>
    </ng-container>

    <!-- Driver Name Column -->
    <ng-container matColumnDef="driver_ln">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Driver Name</th>
      <td mat-cell *matCellDef="let exception">{{ exception.driver_fn }} {{ exception.driver_ln }}</td>
    </ng-container>

    <!-- Student Name Column -->
    <ng-container matColumnDef="student_ln">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Student Name</th>
      <td mat-cell *matCellDef="let exception">{{ exception.student_fn }} {{ exception.student_ln }}</td>
    </ng-container>

    <!-- Stop Name Column -->
    <ng-container matColumnDef="stop_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Stop Name</th>
      <td mat-cell *matCellDef="let exception">{{ exception.stop_name }}</td>
    </ng-container>

    <!-- Alert Column -->
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Alert</th>
      <td mat-cell *matCellDef="let exception">{{ exception.description }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true" ></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" [ngClass]="{ highlight: row.severity == 'critical' }"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
  </div>
</div>
