import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DBConfig } from '@app/models/interfaces';

@Component({
  selector: 'app-add-database-modal',
  templateUrl: './add-database-modal.component.html',
  styleUrls: ['./add-database-modal.component.scss']
})
export class AddDatabaseModalComponent implements OnInit {

  database: DBConfig = {
    name: '',
    id: '',
    primary: false,
    etl_filters: undefined
  }

  constructor(
    public dialogRef: MatDialogRef<AddDatabaseModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }


  ngOnInit(): void {
    if (this.data.database != null) {
      this.database = this.data.database
    }
    
  }


  addDatabase() {
    this.dialogRef.close(this.database)
  }

}
