import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { AlertsService } from '@app/services/alerts.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FilterService } from '@app/services/filter.service';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss'],
})
export class AlertsComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  alertsDataSource: MatTableDataSource<any>;

  displayedColumns: string[] = [
    'bus_name',
    'segment_name',
    'time_stamp',
    'driver_ln',
    'student_ln',
    'stop_name',
    'description',
  ];

  constructor(private alertsService: AlertsService, public filterService: FilterService) {
    this.alertsDataSource = new MatTableDataSource([]);
  }

  ngOnInit() {
    this.alertsService.getExceptions();
    this.alertsService.accountExceptions$.subscribe((e) => {
      this.alertsDataSource.data = e;
    });
  }

  ngAfterViewInit() {
    this.alertsDataSource.sort = this.sort;
    this.alertsDataSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.alertsDataSource.filter = filterValue.trim().toLowerCase();

    if (this.alertsDataSource.paginator) {
      this.alertsDataSource.paginator.firstPage();
    }
  }


}
