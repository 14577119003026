import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ETLFilters, ProviderConfig } from '@app/models/interfaces';
import { AddDatabaseModalComponent } from '../add-database-modal/add-database-modal.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-config-admin-edit-modal',
  templateUrl: './config-admin-edit-modal.component.html',
  styleUrls: ['./config-admin-edit-modal.component.scss']
})
export class ConfigAdminEditModalComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['name', 'edit', 'delete'];

  providerConfig: ProviderConfig = {
    username: '',
    password: '',
    retry: undefined,
    base_url: '',
    client_id: '',
    db_config: [],
    vendor_id: '',
    vendor_key: ''
  };
  databases: ETLFilters[] = []
  private unsubscribe$: Subject<void> = new Subject<void>();


  constructor(
    public dialogRef: MatDialogRef<ConfigAdminEditModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private modal: MatDialog,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    if (!(this.data?.master_plan?.config?.provider_config)) {
      return;
    }
    this.providerConfig.username = this.data.master_plan.config.provider_config.username;
    this.providerConfig.password = this.data.master_plan.config.provider_config.password;
    this.providerConfig.client_id = this.data.master_plan.config.provider_config.client_id;
    this.providerConfig.base_url = this.data.master_plan.config.provider_config.base_url;
    this.databases = this.data.master_plan.config.provider_config.db_config;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }


  editInformation(): void {
    const data = {
      providerConfig: this.providerConfig,
      databases: this.databases
    }
    this.dialogRef.close(data);
  }

  setOtherDatabasesPrimaryToFalse(result: any) {
    this.databases.forEach((db: any) => {
      if (db !== result && db.primary) {
        db.primary = false;
      }
    });
  }

  addNewDatabase() {
    const database = { database: null }
    this.modal.open(AddDatabaseModalComponent, { width: '500px', data: database })
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        if (result) {
          if (result.primary) this.setOtherDatabasesPrimaryToFalse(result)
          this.databases = [...this.databases, result]
        }
      })
  }

  editDatabase(db: any) {
  const database = { database: db }
  this.modal.open(AddDatabaseModalComponent, { width: '500px', data: database })
    .afterClosed()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      if (!result) {
        return;
      }
      if (result.primary) {
        this.setOtherDatabasesPrimaryToFalse(result);
      } else {
        // Check if there's no other primary database
        const noOtherPrimary = this.databases.every((database: any) => !database.primary || database === db);
        if (noOtherPrimary) {
          // Notify the user and stop the operation
          this.snackBar.open('At least one database must be set to primary.', 'Close', {
            duration: 3000,
          });
          // Revert the database to primary = true
          db.primary = true;
          return;
        }
      }
      // Find the index of the database being edited
      const index = this.databases.findIndex(database => database === db);
      // Replace the old database with the edited one
      this.databases[index] = result;
    });
}

  deleteDatabse(db: any) {
    if (db.primary) {
      this.snackBar.open('Cannot delete primary database. Please set another database as primary before deleting.', 'Close', {
        duration: 6000,
      });
      return;
    }
    const index = this.databases.findIndex(database => database === db);
    this.databases.splice(index, 1);
    this.databases = [...this.databases];
  }
}
