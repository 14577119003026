import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { environment as env } from '@environments/environment';
import { ApiService } from '@app/services/api.service';
import { AccountService } from '@app/services/account.service';

@Injectable({
  providedIn: 'root',
})
export class WarningNotificationService {
  constructor(private apiService: ApiService, private accountService: AccountService) {}

  private _getEtlUpdated(accountId: string) {
    const etlUpdatedUrl = `${env.rtbApiUrl}/v1/account/${accountId}/etl_updated`;
    return this.apiService.get(etlUpdatedUrl).pipe(map((e) => e['data']['etl_data_is_recent']));
  }

  public checkEtlUpdated(): Observable<boolean> {
    return this.accountService.getAccountId$().pipe(
      switchMap((e) => this._getEtlUpdated(e))
    );
  }
}
