import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Trip, Bus } from '@app/models/interfaces';
import { TripsService } from '@app/services/trips.service';
import { BusService } from '@app/services/bus.service';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith, debounceTime } from 'rxjs/operators';
import { MatRadioChange } from '@angular/material/radio';

@Component({
  selector: 'app-edit-trip',
  templateUrl: './edit-trip.component.html',
  styleUrls: ['./edit-trip.component.scss'],
})
export class EditTripComponent implements OnInit {
  @Input() trip: Trip;
  // Allows parent component to respond to child event
  @Output() closeEvent = new EventEmitter();

  buses: Bus[];
  newBusControl: UntypedFormControl;
  busOptions: string[];
  filteredOptions$: Observable<string[]>;

  reassignmentVisible: boolean;
  submitButtonDisabled: boolean;
  reassignmentType: string;
  reassignmentSuccess: boolean;
  busToAssign: Bus;

  constructor(private busService: BusService, private tripsService: TripsService) {}

  ngOnInit(): void {
    this.reassignmentVisible = false;
    this.submitButtonDisabled = true;
    this.newBusControl = new UntypedFormControl();
    this.busService.getBuses$().subscribe((resp) => {
      this.buses = resp;
      this.busOptions = resp.map((a) => a['bus_name']);
      this.setFilteredOptions();
      this.newBusControl.valueChanges.subscribe((value) => this.validateSelection(value));
    });
  }

  private setFilteredOptions(): void {
    // Set observable for view template async pipe to subscribe to
    this.filteredOptions$ = this.newBusControl.valueChanges.pipe(
      debounceTime(200),
      startWith(''),
      map((value) => this._filter(value))
    );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.busOptions.filter((option) => option.toLowerCase().includes(filterValue));
  }

  private validateSelection(value: string): void {
    // Only allow reassignment if the user-selected option is a valid bus
    this.busToAssign = this.buses.find(({ bus_name }) => bus_name === value);
    if (this.busToAssign && (this.trip.bus === null || this.busToAssign.id !== this.trip.bus.id)) {
      this.reassignmentVisible = true;
      this.submitButtonDisabled = true;
      this.reassignmentType = undefined;
      this.reassignmentSuccess = false;
    } else {
      this.reassignmentVisible = false;
    }
  }

  submitReassignment(option: string) {
    // option is set by the selected radio button value
    switch (option) {
      case '2':
        this.tripsService.assignBustoAllSegments(this.busToAssign.id, this.trip.bus.id).subscribe((resp) => {
          this._handleReassignmentResponse(resp);
        });
        break;
      default:
        this.tripsService.assignBusToSegment(this.trip.id, this.busToAssign.gtc_id).subscribe((resp) => {
          this._handleReassignmentResponse(resp);
        });
    }
  }

  private _handleReassignmentResponse(resp) {
    // On successful reassignment, briefly display success message and refresh page trips
    if (resp['success']) {
      this.reassignmentSuccess = true;
      setTimeout(() => {
        this.tripsService.getTrips(true);
      }, 1000);
    }
  }

  radioChange(event: MatRadioChange) {
    // Submit button should only be clickable if a radio button is selected
    this.reassignmentType = event.value;
    this.submitButtonDisabled = false;
    this.reassignmentSuccess = false;
  }

  closeEditPane() {
    this.closeEvent.emit();
  }
}
