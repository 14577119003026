import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { AdminComponent } from './components/admin/admin.component';
import { AlertsComponent } from './components/alerts/alerts.component';
import { TripsComponent } from './components/trips/trips.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'trips',
        pathMatch: 'full',
    },
    {
        path: 'trips',
        component: TripsComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'alerts',
        component: AlertsComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'admin',
        component: AdminComponent,
        canActivate: [AuthGuard],
    },
    {
        path: '**',
        redirectTo: '/',
        pathMatch: 'full',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule { }
