<zui-header logoTitle="Routeboard"
            data-cy="header"
            useEmailAsName="true"
            [hasShowChangeCompany]="false"></zui-header>
<div class="body-container"
     [class.zonar-dark-theme]="darkMode">
  <app-warning-notification></app-warning-notification>
  <mat-drawer-container>

    <mat-drawer #drawer
                autoFocus="false"
                mode="over"
                position="end"
                (openedChange)="toggleFilterMode()">

      <app-filter (filterModeEvent)="drawer.toggle()"></app-filter>
    </mat-drawer>
    <mat-drawer-content>
      <app-subheader data-cy="subheader"
                     (darkModeEvent)="toggleDarkMode()"
                     (filterModeEvent)="drawer.toggle()"
                     [filterMode]="filterMode">
      </app-subheader>
      <div *ngIf="contentLoading"
           class="mat-spinner-container">
        <mat-spinner color="accent"></mat-spinner>
      </div>
      <router-outlet></router-outlet>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
<zui-footer></zui-footer>