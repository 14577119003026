import { Injectable } from '@angular/core';   // here

// Angular Modules
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Material Design Modules
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatListModule } from '@angular/material/list';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';

// Core Modules
import { CoreModule } from './core/core.module';

// Pattern Library Modules
import { ZonarUICoreModule } from '@zonar-ui/core';
import { ZonarUiAuthModule } from '@zonar-ui/auth';
import { ZonarUIMaterialModule } from '@zonar-ui/material';
import { ZonarUiAnalyticsService, ZonarUIAnalyticsTokens } from '@zonar-ui/analytics';

// App Modules
import { AppRoutingModule } from '@app/app-routing.module';

// 3rd Party Modules
import { ChartsModule } from 'ng2-charts';

// Components
import { AppComponent } from '@app/app.component';
import { TripsComponent } from '@app/components/trips/trips.component';
import { AlertsComponent } from '@app/components/alerts/alerts.component';
import { SubheaderComponent } from '@app/components/shared/subheader/subheader.component';
import { WarningNotificationComponent } from '@app/components/shared/warning-notification/warning-notification.component';
import { TripDetailsComponent } from '@app/components/trips/trip-details/trip-details.component';
import { SegmentManifestComponent } from '@app/components/trips/segment-manifest/segment-manifest.component';
import { SegmentExceptionsComponent } from '@app/components/trips/segment-exceptions/segment-exceptions.component';
import { EditTripComponent } from '@app/components/trips/edit-trip/edit-trip.component';
import { FilterComponent } from '@app/components/shared/filter/filter.component';
import { KpiComponent } from '@app/components/trips/kpi/kpi.component';
import { TimeDifPipe } from '@app/components/shared/time-dif.pipe';
import { AdminComponent } from './components/admin/admin.component';
import { ConfigAdminEditModalComponent } from './components/shared/config-admin-edit-modal/config-admin-edit-modal.component';
import { AddDatabaseModalComponent } from './components/shared/add-database-modal/add-database-modal.component';
import { DatabaseModalComponent } from './components/shared/database-modal/database-modal.component';


@NgModule({
  declarations: [
    AppComponent,
    TripsComponent,
    AlertsComponent,
    SubheaderComponent,
    WarningNotificationComponent,
    TripDetailsComponent,
    SegmentManifestComponent,
    SegmentExceptionsComponent,
    EditTripComponent,
    FilterComponent,
    KpiComponent,
    TimeDifPipe,
    AdminComponent,
    ConfigAdminEditModalComponent,
    AddDatabaseModalComponent,
    DatabaseModalComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ZonarUICoreModule,
    ZonarUIMaterialModule,
    MatTableModule,
    MatToolbarModule,
    MatIconModule,
    MatSnackBarModule,
    MatPaginatorModule,
    MatSortModule,
    MatListModule,
    MatAutocompleteModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatCheckboxModule,
    ChartsModule
  ],
  providers: [],
  bootstrap: [AppComponent],

})
export class AppModule { }
