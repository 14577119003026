import { Injectable } from '@angular/core';
import { TripsService } from '@app/services/trips.service';
import { AlertsService } from '@app/services/alerts.service';
import { Trip, Driver } from '@app/models/interfaces';
import { DriversService } from '@app/services/drivers.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FilterService {
  constructor(
    private tripsService: TripsService,
    private alertsService: AlertsService,
    private driversService: DriversService
  ) {}

  // These objects are subscribed to by the trips and alerts template views to populate visible filter breadcrumbs.
  public activeTripFilters;
  public activeAlertFilters;

  // These objects store the values of params sent in most recent API request to getTrips / getExceptions.
  public activeTripParams;
  public activeAlertParams;

  /**
   * This is subscribed to in filter.component, and is used to reflect user removal of a filter breadcrumb in the filter template's view.
   * It receives the name of the removed filter, which is used to map a correct de-selection from within the filter component.
   */
  public filterRemovalTracker$ = new BehaviorSubject('');

  // Trips Page

  /**
   * This method handles a user request to filter visible Trips.
   * It accepts two params: `params` and `filters`.
   * @param params used for serverside filtering. Gets passed into the API query to get new trips
   * @param filters used to display currently applied filters in template view, and to flag any clientside filtering required.
   */
  public filterTrips(params?: object, filters?: object) {
    this.activeTripParams = params ? params : undefined;
    this.displayTripFilters(filters);
    this.tripsService.getTrips(true, params);
  }

  /**
   * This method gets called on removal of a mat-chip/breadcrumb filter in the trips template view.
   * On removal of the user-selected filter, activeTripFilters and activeTripParams get updated,
   * and the filterTrips method gets called with the updated params/filters.
   * @param filter is an item created by the *ngFor directive from the activeTripFilters collection.
   * `filter` will always be an object with a single key/value, representing the filter being removed.
   */
  public removeTripFilter(filter: object) {
    this.filterRemovalTracker$.next(filter['key']);
    delete this.activeTripFilters[filter['key']];
    delete this.activeTripParams[filter['key']];
    this.filterTrips(this.activeTripParams, this.activeTripFilters);
  }

  /**
   * This method sets the activeTripFilters value to the user-specified filter object.
   * @param filters is created from the user-selected filter options in the filter.component
   * If `filters` is not passed, activeTripFilters will be set to undefined, which will hide it from the view,
   * due to an *ngIf directive.
   */
  private displayTripFilters(filters?: object) {
    this.activeTripFilters = filters ? filters : undefined;
  }

  // Alerts Page

  /**
   * This method handles a user request to filter visible Alerts.
   * It accepts two params: `params` and `filters`.
   * @param params used for serverside filtering. Gets passed into the API query to get new exceptions/alerts
   * @param filters used to display currently applied filters in template view, and to flag any clientside filtering required.
   */
  public filterAlerts(params?: object, filters?: object) {
    this.activeAlertParams = params ? params : undefined;
    this.displayAlertFilters(filters);
    this.alertsService.getExceptions(true, params);
  }

  /**
   * This method gets called on removal of a mat-chip/breadcrumb filter in the alerts template view.
   * On removal of the user-selected filter, activeAlertFilters and activeAlertParams get updated,
   * and the filterAlerts method gets called with the updated params/filters.
   * @param filter is an item created by the *ngFor directive from the activeAlertFilters collection.
   * `filter` will always be an object with a single key/value, representing the filter being removed.
   */
  public removeAlertFilter(filter: object) {
    this.filterRemovalTracker$.next(filter['key']);
    delete this.activeAlertFilters[filter['key']];
    delete this.activeAlertParams[filter['key']];
    this.filterAlerts(this.activeAlertParams);
  }

  /**
   * This method sets the activeAlertFilters value to the user-specified filter object.
   * @param filters is created from the user-selected filter options in the filter.component
   * If `filters` is not passed, activeAlertFilters will be set to undefined, which will hide it from the view,
   * due to an *ngIf directive.
   */
  private displayAlertFilters(filters?: object) {
    this.activeAlertFilters = filters ? filters : undefined;
  }

  // Client-side Filtering

  /**
   * This function is called in the app.component onInit, and sets up a stage between the Trip[] API response to trips.service.getTrips
   * and those trips actually displayed in the trips template view.
   *
   * The serverFilteredTrips$ BehaviorSubject value is set with the response to all getTrips requests (with or without parameters).
   * This function sets a subscription to that response, then applies any additional filtering requested by the user.
   *
   * This allows us to extend the filtering options beyond those that are explicitly allowed via RTB API endpoint query params.
   *
   * After all clientside filtering is complete, the remaining list of Trips that match filter criteria are set the next value of
   * the displayedTrips$. It's this BehaviorSubject that is actually subscribed to in the trips template.
   *
   * To extend any additional clientside filters, just create a filtering function and set filteredTrips to its returned value
   * before setting is as displayedTrips$ next value.
   */
  public setTripFiltering(): void {
    this.tripsService.serverFilteredTrips$.subscribe((trips: Trip[]) => {
      let filteredTrips = trips;

      if (this.activeTripFilters && this.activeTripFilters['unassigned_bus_only']) {
        filteredTrips = this.filterUnassignedBuses(filteredTrips);
      }

      if (this.activeTripFilters && this.activeTripFilters['late_trips_only']) {
        filteredTrips = this.filterLateTrips(filteredTrips);
      }
      /**
       * This extra bit of conditional logic is to allow 'or' logic filtering for Status-type filters.
       * Each trip status filter option is presented to the user as a checkbox. We would like the user to be able
       * to have multiple status options selected, with the resulting filtered trips properly respecting those multiple
       * choices.
       */
      if (
        this.activeTripFilters &&
        (this.activeTripFilters['completed_trips'] ||
          this.activeTripFilters['not_started_trips'] ||
          this.activeTripFilters['in_progress_trips'])
      ) {
        const statusFiltered = [];

        if (this.activeTripFilters['completed_trips']) {
          statusFiltered.push(this.filterCompletedTrips(filteredTrips));
        }
        if (this.activeTripFilters['not_started_trips']) {
          statusFiltered.push(this.filterNotStartedTrips(filteredTrips));
        }
        if (this.activeTripFilters['in_progress_trips']) {
          statusFiltered.push(this.filterInProgressTrips(filteredTrips));
        }
        filteredTrips = statusFiltered.flat();
      }

      if (this.activeTripFilters && this.activeTripFilters['driver']) {
        filteredTrips = this.filterDriverTrips(filteredTrips);
      }

      this.tripsService.displayedTrips$.next(filteredTrips);
    });
  }

  private filterUnassignedBuses(trips: Trip[]) {
    return trips.filter((trip) => trip['bus'] == null);
  }

  private filterLateTrips(trips: Trip[]) {
    return trips.filter((trip) => trip['ontime'] === false);
  }

  private filterCompletedTrips(trips: Trip[]) {
    return trips.filter((trip) => trip['completion_timestamp'] !== null);
  }

  private filterInProgressTrips(trips: Trip[]) {
    return trips.filter((trip) => trip['in_progress'] && trip['completion_timestamp'] == null);
  }

  private filterNotStartedTrips(trips: Trip[]) {
    return trips.filter((trip) => !trip['in_progress'] && trip['completion_timestamp'] == null);
  }

  /**
   * DriversService.drivers$ value will have been set during filter and trip-details component initialization
   *
   * The account's drivers are filtered for all instances of a matching selected driver name. The array `filter`
   * method is used instead of `find`, so that we can display trips from all drivers with that name.
   *
   * We filter for all drivers that match the selected name, for all trips' both planned and actual driver values.
   */
  private filterDriverTrips(trips: Trip[]) {
    const matchingDrivers: Driver[] = this.driversService.driverObjects$.value.filter(
      (d) => d['name'] === this.activeTripFilters['driver']
    );

    function driverPresent(trip: Trip) {
      for (const driver of matchingDrivers) {
        if ((trip.actual_driver || trip.planned_driver) === driver.id) {
          return true;
        }
      }
    }

    return trips.filter(driverPresent);
  }
}
