import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ApiService {
    private options = {
        headers: new HttpHeaders().set('Content-Type', 'application/json')
    };

    constructor(private httpClient: HttpClient) { }

    public get(url: string, params?: object, retries?: number): Observable<any> {
        if (retries == null) {
            retries = 3;
        }
        if (params) {
            const httpParams: HttpParams = new HttpParams({ fromObject: params as any });
            return this.httpClient.get(url, { params: httpParams }).pipe(retry(retries), catchError(this.formatErrors));
        }
        return this.httpClient.get(url).pipe(retry(retries), catchError(this.formatErrors));
    }

    public getCsv(url: string): Observable<any> {
        return this.httpClient.get(url, {responseType: 'text'}).pipe(retry(3), catchError(this.formatErrors));
    }

    public post(url, body: object = {}): Observable<any> {
        return this.httpClient.post(url, JSON.stringify(body), this.options).pipe(catchError(this.formatErrors));
    }

    public put(url, body: object = {}): Observable<any> {
        return this.httpClient.put(url, JSON.stringify(body), this.options).pipe(catchError(this.formatErrors));
    }

    public patch(url, body: object = {}): Observable<any> {
        return this.httpClient.patch(url, JSON.stringify(body), this.options).pipe(catchError(this.formatErrors));
    }

    public formatErrors(error: any): Observable<any> {
        return throwError(error.error);
    }
}
