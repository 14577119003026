import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { ZonarUiAnalyticsService } from '@zonar-ui/analytics';
import { DriversService } from './services/drivers.service';
import { StudentsService } from './services/students.service';
import { FilterService } from './services/filter.service';
import { TripsService } from './services/trips.service';
import { AccountService } from './services/account.service';
import { take } from 'rxjs/operators';
import { AuthProfile } from '@zonar-ui/auth';

declare let pendo: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    public auth: AuthService,
    private router: Router,
    private zuiAnalytics: ZonarUiAnalyticsService,
    private tripsService: TripsService,
    private driversService: DriversService,
    private studentsService: StudentsService,
    private filterService: FilterService,
    private accountService: AccountService
  ) { }


  title = 'Zonar Routeboard';
  darkMode: boolean;
  filterMode: boolean;
  contentLoading: boolean;
  userProfile: AuthProfile;
  accountCode: string;

  ngOnInit() {

    this.zuiAnalytics.addGtmToDom();

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const tag = { event: 'page', pageName: event.url };
        this.zuiAnalytics.pushTag(tag);
      }
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.contentLoading = true;
      }
      if (event instanceof NavigationEnd) {
        this.contentLoading = false;
      }
    });

    const loginSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd && (!this.router.url.includes('code=') || !this.router.url.includes('state='))) {
        this.auth.isAuthenticated$.subscribe(resp => {
          if (resp) {
            loginSubscription.unsubscribe();
            // We only want to kick these off if it's confirmed that the user is logged in
            // The following two lines gather information required by pendo
            this.auth.user$.pipe(take(1)).subscribe(user => this.userProfile = user)
            this.accountService.accountCode$.pipe(take(2)).subscribe(accountCode => {
              if (accountCode.length > 0) {

                pendo.initialize({
                  visitor: {
                    id: this.userProfile.sub,   // Required if user is logged in
                    email: this.userProfile.email,        // Recommended if using Pendo Feedback, or NPS Email
                    full_name: this.userProfile.name,
                    role: 'Routeboard User'    // Recommended if using Pendo Feedback
                    // role:         // Optional

                    // You can add any additional visitor level key-values here,
                    // as long as it's not one of the above reserved names.
                  },

                  account: {
                    id: accountCode // Highly recommended
                    // name:         // Optional
                    // is_paying:    // Recommended if using Pendo Feedback
                    // monthly_value:// Recommended if using Pendo Feedback
                    // planLevel:    // Optional
                    // planPrice:    // Optional
                    // creationDate: // Optional

                    // You can add any additional account level key-values here,
                    // as long as it's not one of the above reserved names.
                  }
                });

              }
            });

            this.filterService.setTripFiltering();
            this.tripsService.getTrips();
            this.studentsService.getStudents();
            this.driversService.getDrivers();
            this.accountService.getFullAccount$().pipe(take(1)).subscribe();
            this.router.navigateByUrl('/trips');
            return;
          }
          this.auth.loginWithRedirect()
        });
      }
    });

  }

  toggleDarkMode(): void {
    this.darkMode = !this.darkMode;
  }

  toggleFilterMode(): void {
    this.filterMode = !this.filterMode;
  }

}
